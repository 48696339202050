import React from 'react';
import './Plans.css'; 
import { ReactComponent as RightArrow } from '../../assets/right-arrow.svg';
import { ReactComponent as Timer } from '../../assets/timer.svg';

const Plans = () => {
  return (
    <div className="plans-container" id="Plans">
      <div className="plans-top">
        <h2>Book A Cups Of Smiles Experience Today</h2>
        <p>Our services are available only in Mumbai City, at present. Choose from the following experience options, schedule a slot of your choice on our calendar, make a payment and get an instant confirmation.</p>
      
      </div>
      <div className="plans">
        <div className="plan-box">
          <h2>Casual Conversations </h2>
          <h3><Timer/> 60 minutes</h3>
          <hr />
          <ul>
            <li>Can be scheduled on any day of the week.</li>
            <li>Between 7 am to 11 pm</li>
            <li>Location of your choice</li>
            <li>Best for a free-wheeling but meaningful conversation</li>
          </ul>
          <div className="note">Note: This Does Not Include Hospitality Provided By You.</div>
          <a href="tel:+918097212002">
            <div className="button">BOOK A CALL</div>
          </a>
        </div>

        <div className="plan-box">
          <h2>Create Your Own</h2>
          <h3><Timer/> 120 minutes</h3>
          <hr />
          <ul>
            <li>Can be scheduled on any day of the week.</li>
            <li>Between 7 am to 11 pm</li>
            <li>Location of your choice</li>
            <li>Create your own experience such as a food trail, a museum visit or ask our experts to suggest.</li>
          </ul>
          <div className="note">Note: This Does Not Include Hospitality Provided By You.</div>
          <a href="tel:+918097212002">
            <div className="button">BOOK A CALL</div>
          </a>
        </div>

        <div className="plan-box">
          <h2>Be my Plus One</h2>
          <h3><Timer/> 120 minutes</h3>
          <hr />
          <ul>
            <li>Can be scheduled on any day of the week.</li>
            <li>Between 7 am to 11 pm</li>
            <li>Location of your choice</li>
            <li>Suitable for a business or social event when you need a Plus One.</li>
          </ul>
          <div className="note">Note: This Does Not Include Hospitality Provided By You.</div>
          <a href="tel:+918097212002">
            <div className="button">BOOK A CALL</div>
          </a>
        </div>
        <div className="plan-box travel-plan">
          <h2>Travel Times</h2>
          <ul>
            <li>Looking for a travel companion ? Call our experts for details</li>
            <hr />
            <li className="note">Note: This Does Not Include Hospitality Provided By You.</li>
            <hr />
          </ul>
          <a href="tel:+918097212002">
            <div className="button">BOOK A CALL</div>
          </a>
        </div>
      </div>

    </div>
  );
};

export default Plans;
