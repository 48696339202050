import React from 'react';
import './Features.css';

const Features = () => {
  return (
    <div className="core-values" id="Features">
      <h1>Discover The Cups Of Smiles Experience</h1>
      <p>Step into a world where genuine connections thrive. Cups of Smiles offers more than just companionship — it's about sharing laughter, creating memories, and finding joy in the company of like-minded souls. We’re not in the business of dating and romance and believe in the power of fuss- free, basic human connections.</p>
      <div className="values-container">
        <div className="value-box">
          <h2>Simple & Secure</h2>
          <p>Experience a safe space to connect with friendly, engaging individuals who share your interests. </p>
        </div>
        <div className="value-box">
          <h2>Tailored Companionship</h2>
          <p>Get personalised matches that resonate with your interests and vibe.</p>
        </div>
        <div className="value-box">
          <h2>Diverse Activities</h2>
          <p>From city tours to heritage walks, to just coffee or a morning walk – choose from a range of possible experiences.</p>
        </div>
        <div className="value-box">
          <h2>Trusted Community</h2>
          <p>Every Cup of Smiles companion undergoes a rigorous screening process for authenticity and reliability.</p>
        </div>
        <div className="value-box">
          <h2>Flexible Booking</h2>
          <p>Schedule your meet-ups at your convenience, ensuring a hassle-free experience.</p>
        </div>
        <div className="value-box">
          <h2>Security and Privacy</h2>
          <p>Prioritising user privacy with a secure portal, implementing rigorous measures to protect user data and uphold trust.</p>
        </div>
      </div>
    </div>
  );
};

export default Features;
