import React from 'react';
import './About.css';
import { ReactComponent as RightArrow } from '../../assets/right-arrow.svg'

const About = () => {
  return (
    <div className="about" id="About">
      <div className="about-image">
        <img src="path/to/your/image.png" alt="About Image" />
      </div>
      <div className="about-content">
        <h1>Importance of Cups Of Smiles</h1>
        <p>
        Envision a world devoid of companionship—an undoubtedly lonely existence, particularly for seniors who often grapple with feelings of isolation more than any other age group.
        So, what does companionship truly entail? It transcends mere presence; it's about desiring someone's company, relishing the moments shared, and fostering a connection that feels inherently natural.
        </p>
        <button className="book-button">Book your companion now <RightArrow/> </button>
      </div>
    </div>
  );
};

export default About;
