import React from 'react'
import './Founder.css'
import img1 from '../../assets/Founder/1.png';
import img2 from '../../assets/Founder/2.jpg';
import img3 from '../../assets/Founder/3.png';

function Founder() {
  return (
    <div className='Founder' id='Founder'>
        <h1>The Cups Of Smiles Story</h1>
        <div className="images">
            <img src={img1} alt="1" />
            <img src={img2} alt="2" />
            <img src={img3} alt="3" />
        </div>
        <div className="text">
            <p>Meet <span>Nicole Liz Faria</span>, our Founder and CEO.</p>
            <p>Nicole believes that at Cups of Smiles, has a mission is to cultivate a world where authentic connections flourish. In her words … “ We believe in the profound impact of simple, human connections—where laughter is shared, memories are created, and joy is found in the camaraderie of kindred spirits. We are not in the business of matchmaking; rather, we champion the essence of genuine companionship. Our concept is rooted in the power of fuss-free connections, offering a sanctuary for those seeking the beauty of pure human interaction. Welcome to Cups of Smiles, where every encounter is a celebration of shared moments and the joy of being."</p>
            <p>In her other avatar – Nicole is a creative entrepreneur has curated the Indian Chef Awards – a pan India event that saw participation by over 1,000 chefs culminating in a grand finale that was live streamed across multiple platforms. She is building <span>Happy Women Global</span> – a super app that empowers women to become the best version of themselves.</p>
        </div>
    </div>
  )
}

export default Founder