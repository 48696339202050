import React, { useState, useEffect, useRef } from 'react';
import './Navbar.css'; 
import { ReactComponent as RightArrow } from '../../assets/right-arrow.svg'
import { ReactComponent as Logo } from '../../assets/logo.svg'
import { Link } from "react-scroll";

const Navbar = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const navbarRef = useRef(null);

  const toggleNavbar = () => {
    setNavbarOpen(!navbarOpen);
  };

  const closeNavbarOnOutsideClick = (event) => {
    if (navbarRef.current && !navbarRef.current.contains(event.target)) {
      setNavbarOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', closeNavbarOnOutsideClick);

    return () => {
      document.removeEventListener('click', closeNavbarOnOutsideClick);
    };
  }, []);

  return (
    <nav className="navbar" id="Navbar" ref={navbarRef}>
      <div className="logo">
        <div>
          <Logo style={{ width: "88px", height: "88px" }}/>
        </div>
        <div className="nav-mobile">
          <span id="navbar-toggle" className={`${navbarOpen ? 'active' : ''}`} onClick={toggleNavbar}>
            <span></span>
          </span>
        </div>
      </div>
      <ul className={`nav-items ${navbarOpen ? 'active' : ''}`}>
        <li><Link to='Landing' activeClass='navbar_link_active' spy={true} smooth={true} duration={300} offset={-72} onClick={toggleNavbar}>Home</Link></li>
        <li><Link to='Features' activeClass='navbar_link_active' spy={true} smooth={true} duration={300} offset={-72} onClick={toggleNavbar}>How does it work</Link></li>
        <li><Link to='Plans' activeClass='navbar_link_active' spy={true} smooth={true} duration={300} offset={-72} onClick={toggleNavbar}>Our smiles</Link></li>
        <li><Link to='FAQs' activeClass='navbar_link_active' spy={true} smooth={true} duration={300} offset={-72} onClick={toggleNavbar}>FAQs</Link></li>
        <li><Link to='ContactUs' activeClass='navbar_link_active' spy={true} smooth={true} duration={300} offset={-72} onClick={toggleNavbar}>Contact Us</Link></li>
      </ul>
      <div className="button">
        <a href="tel:+918097212002">
          Book a smile 
        </a>
      </div>
    </nav>
  );
};

export default Navbar;
