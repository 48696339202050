import React, { useState } from 'react';
import './ContactUs.css';
import { ReactComponent as Instagram } from '../../assets/instagram.svg'
import { ReactComponent as LinkedIn } from '../../assets/linkedin.svg'
import { ReactComponent as RightArrow } from '../../assets/right-arrow.svg'
import { ReactComponent as Facebook } from '../../assets/facebook.svg';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    Name: '',
    Email: '',
    Number: '',
    Message: '',
  });

  const [submitStatus, setSubmitStatus] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setSubmitStatus("Submitting...")

    const formDataBody = new FormData();
    formDataBody.append('Name', formData.Name);
    formDataBody.append('Email', formData.Email);
    formDataBody.append('Number', formData.Number);
    formDataBody.append('Message', formData.Message);
    
    try {
      const response = await fetch(
        'https://script.google.com/macros/s/AKfycbzp10LfUuQRE_z7D0mymQL3v0RiPRXXyMZwkIKOEVs2pWh0ZPVPXdOaMj4e-lJYqHWn/exec',
        {
          method: 'POST',
          body: formDataBody,
        }
      );
      const data = await response.json();
      if (data.result === 'success') {
        clearForm();
        setSubmitStatus("Submitted successfully, We'll revert to you soon.")
      } else {
        setSubmitStatus("Submission failed, try again later.")
      }
    } catch (error) {
      setSubmitStatus("Submission failed, try again later.")
      console.error('An error occurred during form submission', error);
    }
    setTimeout(resetSubmitStatus, 5000);
  };

  const resetSubmitStatus = () => {
    setSubmitStatus("");
  }

  const clearForm = () => {
    setFormData({
      Name: '',
      Email: '',
      Number: '',
      Message: '',
    });
  };

  return (
    <div className="contact-us-container" id="ContactUs">
      <div className="contact-info">
        <h1>Got a Question about our Smiles ? <br/> We're here to help !</h1>
        <p>Please share your question with your contact information and we'll be in touch!</p>
        <div className="social-icons">
          <a href="https://www.facebook.com/CupsofSmiles">
            <div>
              <Facebook style={{width:"20px", height:"20px"}}/>
            </div>
          </a>

          <a href="https://www.instagram.com/cupsofsmilesofficial/">
            <div>
              <Instagram style={{width:"20px", height:"20px"}}/>
            </div>
          </a>
          <a href="https://www.linkedin.com/company/cupsofsmiles/">
            <div>
              <LinkedIn style={{width:"20px", height:"20px"}}/>
            </div>
          </a>
        </div>
      </div>

      <div className="contact-form">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input onChange={handleChange} placeholder='Your Name' type="text" id="contactus-name" name="Name" value={formData.Name} required />
          </div>

          <div className="form-group">
            <input onChange={handleChange} placeholder='Your Email' type="email" id="contactus-email" name="Email" value={formData.Email} required />
          </div>

          <div className="form-group">
            <input onChange={handleChange} placeholder='Phone Number' type="tel" id="contactus-number" name="Number" value={formData.Number} required />
          </div>

          <div className="form-group">
            <textarea onChange={handleChange} name="Message" value={formData.Message} id="contactus-message" placeholder='Message' rows="4" required></textarea>
          </div>

          <div className='submit-button-container'>
            <button className='button' type="submit">Submit</button>
            <span className='submit-status'>{submitStatus}</span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
