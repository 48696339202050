import React, { useState } from 'react';
import './FAQ.css'; 
import FAQs from './FAQs.json';
import { ReactComponent as Dropdown } from '../../assets/dropdown-arrow.svg';

const FAQ = () => {
  const [activeQuestion, setActiveQuestion] = useState(null);
  const [showAll, setShowAll] = useState(false);

  const toggleAnswer = (index) => {
    setActiveQuestion(activeQuestion === index ? null : index);
  };

  const visibleFAQs = showAll ? FAQs : FAQs.slice(0, 7);

  return (
    <div className="faq" id="FAQs">
      <h1>Our Most <span style={{color: "var(--brown)"}}>Frequently</span> Asked Questions</h1>
      <div className="faq-list">
        {visibleFAQs.map(({question, answer}, index) => (
          <div key={index} className={`faq-item ${activeQuestion === index ? 'active' : ''}`}>
            <div className="question-container" onClick={() => toggleAnswer(index)}>
              <div className="question">
                {question}
              </div>
              <div className="dropdown"><Dropdown/></div>
            </div>
            <div className="answer">
              <p>{answer}</p>
            </div>
          </div>
        ))}
      </div>
      <div className='faq-show-more'>
      {!showAll && (
        <button className="button" onClick={() => setShowAll(true)}>
          Read More
        </button>
      )}
      </div>
    </div>
  );
};

export default FAQ;
