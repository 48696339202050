import React, { useState } from 'react';
import './Landing.css';
import { ReactComponent as RightArrow } from '../../assets/right-arrow.svg'
// import { ReactComponent as DownArrow } from '../../assets/down-arrow.svg'
import { ReactComponent as LeftArrow } from '../../assets/left-arrow.svg'
// import bg1 from '../../assets/Home/1.jpg';
import bg2 from '../../assets/Home/2.jpg';
import bg3 from '../../assets/Home/3.jpg';
import bg4 from '../../assets/Home/4.jpg';
import { Link } from "react-scroll";

const Landing = () => {
  const [currentImageNumber, setCurrentImageNumber] = useState(1);

  const toggleBackgroundImage = dxn => {
    setCurrentImageNumber((prevImage) => (prevImage + dxn + 3) % 3);
  };

  const getImageName = () => {
    return "0" + (currentImageNumber ? currentImageNumber : 3);
  };
  const getImage = () => {
    switch (currentImageNumber) {
      case 1:
        return bg2;
      case 2:
        return bg3;
      case 0:
        return bg4;
      default:
        return '';
    }
  };

  return (
    <div className="landing" id="Landing" style={{ backgroundImage: `linear-gradient(var(--lightbrown),var(--landing-color)), url(${getImage()})`, backgroundSize: "cover", backgroundPosition: "center" }}>
      <div className="content">
        {/* <h2>Companions Are Not Just Paid Partners</h2> */}
        <h4>Discover Meaningful Companionship with Cups of Smiles</h4>
        <h1>Connect, Share, Smile or
          <br /> Simply Just Be</h1>
        <div className="button">
          <Link to="Plans" smooth={true} duration={200} offset={-92}>
            Book a Cups of Smiles Experience
          </Link>
        </div>
      </div>
      <div className="background">
        <div className="image-togglers">
          <div className="toggler left" onClick={() => toggleBackgroundImage(3)}>
            <LeftArrow />
          </div>
          <div className="toggler right" onClick={() => toggleBackgroundImage(1)}>
            <RightArrow />
          </div>
        </div>
        <div className='indicator'>{getImageName()}</div>
      </div>
    </div>
  );
};

export default Landing;
