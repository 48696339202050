import React from 'react';
import './Footer.css'; 
import { ReactComponent as Logo } from '../../assets/logo.svg'
import { ReactComponent as Instagram } from '../../assets/instagram.svg'
import { ReactComponent as LinkedIn } from '../../assets/linkedin.svg'
import { ReactComponent as Send } from '../../assets/send.svg'
import { ReactComponent as Facebook } from '../../assets/facebook.svg';
import { Link } from "react-scroll";

const Footer = () => {
  return (
    <footer className="footer" id="Footer">
      <div className="footer-left">
        <div className="logo"><Logo/></div>
        <p>Copyright ©️ 2024 Cups Of Smiles Pvt.</p>
        <p>All rights reserved</p>
        <div className="social-icons">
          <a href="https://www.facebook.com/CupsofSmiles"><div><Facebook style={{width:"20px", height:"20px"}}/></div></a>
          <a href="https://www.instagram.com/cupsofsmilesofficial/"><div><Instagram style={{width:"20px", height:"20px"}}/></div></a>
          <a href="https://www.linkedin.com/company/cupsofsmiles/"><div><LinkedIn style={{width:"20px", height:"20px"}}/></div></a>
        </div>
      </div>
      <div className="footer-right">
        <div className="column">
          <h3>Company</h3>
          <ul>
            <li><Link to="Features" smooth={true} duration={200} offset={-72}>About Us</Link></li>
            <li><Link to="ContactUs" smooth={true} duration={200} offset={-72}>Contact Us</Link></li>
            <li><a href="tel:+918097212002">Book</a></li>
          </ul>
        </div>

        <div className="column">
          <h3>Support</h3>
          <ul>
            <li><a href="/TermsAndConditions">Terms and Conditions</a></li>
            <li><a href="/PrivacyPolicy">Privacy Policy</a></li>
            <li><Link to="FAQs" smooth={true} duration={200} offset={-72}>FAQ's</Link></li>
          </ul>
        </div>

        {/* <div className="column">
          <h3>Email Us</h3>
          <div className="email-form">
            <input type="email" placeholder="Your email address" />
            <div className='email-send'><Send/></div>
          </div>
        </div> */}
      </div>
    </footer>
  );
};

export default Footer;
