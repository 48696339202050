import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import About from './pages/About/About';
import Features from './pages/Features/Features';
import FAQ from './pages/FAQ/FAQ';
import Footer from './pages/Footer/Footer';
import Landing from './pages/Landing/Landing';
import Navbar from './pages/Navbar/Navbar';
import Reviews from './pages/Reviews/Reviews';
import Plans from './pages/Plans/Plans';
import ContactUs from './pages/ContactUs/ContactUs';
import Ratings from './pages/Ratings/Ratings.jsx';
import TopRibbon from './pages/TopRibbon/TopRibbon';
import FooterBefore from './pages/FooterBefore/FooterBefore';
import TermsAndConditions from './pages/TermsAndConditions/TermsAndConditions.jsx';
import Founder from './pages/Founder/Founder.jsx';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy.jsx';

function App() {
  return (
    <div className="App">
        <Routes>
          <Route exact path="/" element={
            <>
            {/* <TopRibbon/> */}
            <Navbar/>
            <Landing/>
            <Features/>
            {/* <Reviews/> */}
            <Plans/>
            {/* <About/> */}
            {/* <Ratings/> */}
            <Founder/>
            <FAQ/>
            <ContactUs/>
            {/* <FooterBefore/> */}
            <Footer/>
            </>
          }/>
          <Route exact path="/TermsAndConditions" element={<TermsAndConditions />} /> 
          <Route exact path="/PrivacyPolicy" element={<PrivacyPolicy />} /> 
        </Routes>
      </div>
  );
}

export default App;
